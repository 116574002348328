import {
  CardStyleInterpolators,
  createStackNavigator,
} from "@react-navigation/stack";
import { isHorizontalMode } from "components/utils/deviceMode";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useWindowDimensions } from "react-native";
import { ResetPasswordEmailCheckScreen } from "screens/profile/ResetPasswordEmailCheckScreen";
import { Clickable, Icon } from "../components";
import {
  ChangeInfosScreen,
  ChangePasswordScreen,
  LogInScreen,
  ParamsScreen,
  ResetPasswordEmailScreen,
  ResetPasswordScreen,
  SignUpScreen,
  ValidateAccountScreen,
} from "../screens";
import useTheme from "../theme/ThemeProvider";
import fnStyles from "./HeaderStyle";
import { LoginMandatoryParamsList } from "./LoginMandatoryParamsList";
import { useUser } from "hooks";

const LoginMandatoryStack = createStackNavigator<LoginMandatoryParamsList>();

export const LoginMandatoryStackNavigator = () => {
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();
  const styles = fnStyles(theme, isHorizontalMode({ width, height }));
  const { t } = useTranslation();
  const { user } = useUser();

  const headerLeftStyle = useMemo(
    () => ({
      marginLeft: isHorizontalMode({ width, height })
        ? 85
        : theme.normalize(theme.sizings.small),
    }),
    [height, theme, width]
  );

  const headerLeft = useCallback(
    (props: any) => (
      <Clickable
        onPress={props.onPress!}
        style={headerLeftStyle}
        ariaLabel="Retour à la page précédente"
      >
        <Icon
          name={"chevron-small-left"}
          type={"Entypo"}
          colorName={"white"}
          sizeName={"high"}
        />
      </Clickable>
    ),
    [headerLeftStyle]
  );

  return (
    <LoginMandatoryStack.Navigator
      screenOptions={{
        cardStyleInterpolator: CardStyleInterpolators.forNoAnimation,
        headerTitleAlign: "center",
      }}
      initialRouteName={user ? "paramsHome" : "signUp"}
    >
      <LoginMandatoryStack.Screen
        name={"logIn"}
        component={LogInScreen}
        options={() => ({
          headerStyle: styles.container,
          title: `${t("header.connection")} - ${theme.texts.appName}`,
          headerTitle: t("header.connection") ?? "",
          headerTintColor: theme.colors.white,
          headerTitleStyle: styles.title,
        })}
      />
      <LoginMandatoryStack.Screen
        name={"paramsHome"}
        component={ParamsScreen}
        options={() => ({
          headerStyle: styles.container,
          title: `${t("header.welcome")} - ${theme.texts.appName}`,
          headerTitle: t("header.welcome") ?? "",
          headerTintColor: theme.colors.white,
          headerTitleStyle: styles.titleWithoutLeft,
        })}
      />
      <LoginMandatoryStack.Screen
        name={"signUp"}
        component={SignUpScreen}
        options={() => ({
          headerStyle: styles.container,
          title: `${t("header.signUp")} - ${theme.texts.appName}`,
          headerTitle: t("header.signUp") ?? "",
          headerTintColor: theme.colors.white,
          headerTitleStyle: styles.title,
        })}
      />
      <LoginMandatoryStack.Screen
        name={"resetPassword"}
        component={ResetPasswordScreen}
        options={() => ({
          headerStyle: styles.container,
          title: `${t("header.resetPassword")} - ${theme.texts.appName}`,
          headerTitle: t("header.resetPassword") ?? "",
          headerTintColor: theme.colors.white,
          headerTitleStyle: styles.titleWithoutLeft,
          headerLeft: headerLeft,
        })}
      />
      <LoginMandatoryStack.Screen
        name={"resetPasswordEmail"}
        component={ResetPasswordEmailScreen}
        options={() => ({
          headerStyle: styles.container,
          title: `${t("header.resetPassword")} - ${theme.texts.appName}`,
          headerTitle: t("header.resetPassword") ?? "",
          headerTintColor: theme.colors.white,
          headerTitleStyle: styles.titleWithoutLeft,
          headerLeft: headerLeft,
        })}
      />
      <LoginMandatoryStack.Screen
        name={"resetPasswordEmailCheck"}
        component={ResetPasswordEmailCheckScreen}
        options={() => ({
          headerStyle: styles.container,
          title: `${t("header.resetPassword")} - ${theme.texts.appName}`,
          headerTitle: t("header.resetPassword") ?? "",
          headerTintColor: theme.colors.white,
          headerTitleStyle: styles.titleWithoutLeft,
          headerLeft: headerLeft,
        })}
      />
      <LoginMandatoryStack.Screen
        name={"changePassword"}
        component={ChangePasswordScreen}
        options={() => ({
          headerStyle: styles.container,
          title: `${t("header.changePassword")} - ${theme.texts.appName}`,
          headerTitle: t("header.changePassword") ?? "",
          headerTintColor: theme.colors.white,
          headerTitleStyle: styles.titleWithoutLeft,
          headerLeft: headerLeft,
        })}
      />
      <LoginMandatoryStack.Screen
        name={"changeInfos"}
        component={ChangeInfosScreen}
        options={() => ({
          headerStyle: styles.container,
          title: `${t("header.changeInfos")} - ${theme.texts.appName}`,
          headerTitle: t("header.changeInfos") ?? "",
          headerTintColor: theme.colors.white,
          headerTitleStyle: styles.titleWithoutLeft,
          headerLeft: headerLeft,
        })}
      />
      <LoginMandatoryStack.Screen
        name={"validateAccount"}
        component={ValidateAccountScreen}
        options={{
          title: `${t("header.validateAccount")} - ${theme.texts.appName}`,
          headerTitle: t("header.validateAccount") ?? "",
          headerStyle: styles.container,
          headerTintColor: theme.colors.white,
          headerTitleStyle: styles.title,
        }}
      />
    </LoginMandatoryStack.Navigator>
  );
};
