import { StackScreenProps } from "@react-navigation/stack";
import { BoxedLayout } from "components/common/layout/BoxedLayout";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BackgroundLayout,
  Button,
  Card,
  JText,
  JTextInput,
  SView,
  Spacer,
} from "../../components";
import useUser from "../../hooks/useUser";
import { ParamsParamsList } from "../../navigation";
import useTheme from "../../theme/ThemeProvider";
import fnStyles from "./ResetPasswordEmailScreenStyle";
import * as Yup from "yup";

type Props = StackScreenProps<ParamsParamsList, "resetPasswordEmail">;

const emailSchema = Yup.string()
  .email("L'email n'est pas valide")
  .required("L'email est requis");

export const ResetPasswordEmailScreen: React.FC<Props> = ({ navigation }) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const styles = fnStyles(theme);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState<string | null>(null);

  const { forgotPassword, errorMessage, cleanError } = useUser();

  useEffect(() => {
    cleanError();
  }, [cleanError]);

  const validateEmail = async (text: string) => {
    try {
      await emailSchema.validate(text);
      setEmailError(null);
      return true;
    } catch (error: any) {
      setEmailError(error.message);
      return false;
    }
  };

  const onSubmit = async () => {
    const isValid = await validateEmail(email);
    if (!isValid) return;

    const res = await forgotPassword(email);
    if (res && !res?.error) {
      navigation.navigate("resetPasswordEmailCheck");
    }
    return res;
  };

  const onBack = () => {
    setEmail("");
    setEmailError(null);
    cleanError();
    navigation.navigate("logIn");
  };

  return (
    <BackgroundLayout
      style={{
        marginTop: theme.normalize(-30),
        paddingTop: theme.normalize(30),
      }}
    >
      <SView>
        <BoxedLayout style={styles.contentContainer}>
          <Card>
            <JText
              labelKey="auth.reinitPasswordTitle"
              isBold
              centered
              titleLevel={2}
              titleLevelStyle={2}
            />
            <JText
              labelKey="auth.reinitPasswordMailInfo"
              sizeName={"mediumLarge"}
              style={{ marginTop: theme.normalize(theme.sizings.medium) }}
            />
            <JTextInput
              name="email"
              value={email}
              onChangeValue={(value: any) => {
                setEmail(value);
                if (emailError) validateEmail(value);
              }}
              icon="envelope"
              label={t("common.email")}
              keyboardType="email-address"
              containerStyle={{
                marginTop: theme.normalize(theme.sizings.mediumLarge),
              }}
              error={!!emailError} // ICI LE CHAMP ERROR EST UN BOOLEAN
              // errorText={emailError} // Affiche le texte de l'erreur si elle existe
            />
            {emailError && (
              <>
                <Spacer height="medium" backgroundColor={"transparent"} />
                <JText
                  label={emailError}
                  colorName={"danger"}
                  sizeName="mediumLarge"
                  centered
                />
              </>
            )}
            {/* note: we don't display a real error because of privacy, but we use it to display a neutral message after submitting */}
            {errorMessage && (
              <>
                <Spacer height="medium" backgroundColor={"transparent"} />
                <JText
                  labelKey="auth.maybeSendMail"
                  colorName={"dark"}
                  sizeName="mediumLarge"
                  centered
                />
              </>
            )}
            <Button
              type={"gradient"}
              labelKey="common.validate"
              onPress={onSubmit}
              containerStyle={styles.button}
              disabled={!email}
            />
            <Button
              type={"gradient"}
              labelKey="auth.back"
              onPress={onBack}
              containerStyle={styles.bottomButton}
            />
          </Card>
        </BoxedLayout>
      </SView>
    </BackgroundLayout>
  );
};
