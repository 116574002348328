import React from "react";
import { Card, Divider, JText } from "../jlib";
import { View, ActivityIndicator, useWindowDimensions } from "react-native";
import { isHorizontalMode } from "components/utils/deviceMode";
import { SwitchWithIcon } from "../common";
import fnStyles from "./ParamsItemStyle";
import useTheme from "../../theme/ThemeProvider";
import { useUser } from "../../hooks";

type Props = {
  id: number;
  name: string;
  description: string;
  onPress: () => void;
  value: boolean;
  displayDivider?: boolean;
};

export const ParamsItem: React.FC<Props> = ({
  id,
  name,
  description,
  onPress,
  value,
  displayDivider,
}) => {
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();

  const styles = fnStyles(theme, isHorizontalMode({ width, height }));

  const { isParamItemChanging } = useUser();

  const onToggle = () => {
    onPress();
  };

  return (
    <Card style={styles.container} disabled noShadow>
      <View style={styles.contentContainer}>
        <View style={styles.main}>
          <JText label={name} isBold nativeID={`toggle-label-${name}`} />
          <JText
            label={description}
            isItalic
            sizeName={"mediumLarge"}
            fontWeight={"300"}
          />
        </View>
        {isParamItemChanging === `${id}` ? (
          <View style={{ padding: theme.sizings.small }}>
            <ActivityIndicator color={theme.colors.greyDark} size="small" />
          </View>
        ) : (
          <SwitchWithIcon
            value={value}
            onToggle={onToggle}
            iconType={"MaterialCommunity"}
            iconName={value ? "check" : "close"}
            accessibilityLabelledBy={`toggle-label-${name}`}
          />
        )}
      </View>
      {displayDivider && <Divider style={styles.divider} />}
    </Card>
  );
};
