import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";
import { Types } from "types";

export default (theme: Theme, type: Types["inputTypes"]) =>
  StyleSheet.create({
    container: {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "flex-start",
      height: type === "textarea" ? undefined : theme.normalize(50),
      minHeight:
        type === "textarea" ? theme.normalize(200) : theme.normalize(40),
      backgroundColor: theme.colors.fieldFill,
      borderRadius: theme.normalize(theme.radius.small),
      borderColor: theme.colors.fieldBorder,
      borderWidth: 1,
      maxHeight: theme.normalize(400),
    },
    containerError: {
      borderColor: theme.colors.danger,
      borderWidth: 1,
    },
    containerElevated: {
      shadowColor: theme.colors.dark,
      shadowOffset: { width: 0, height: 0 },
      shadowOpacity: 0.25,
      shadowRadius: 1.25,
      elevation: 1,
    },
    icon: {
      paddingLeft: theme.normalize(10),
      paddingRight: 0,
      marginTop: theme.sizings.mediumLarge * 0.6,
    },
    text: {
      flex: 1,
      backgroundColor: "transparent",
      color: theme.colors.tinyElementsText,
      paddingLeft: theme.normalize(16),
      height: "100%",
      paddingTop: type === "textarea" ? theme.sizings.medium * 0.7 : undefined,
    },
    textError: {},
    eye: {
      paddingRight: theme.normalize(10),
      marginTop: theme.sizings.mediumLarge * 0.6,
    },
  });
