import { BoxedLayout } from "components/common/layout/BoxedLayout";
import { isHorizontalMode } from "components/utils/deviceMode";
import React, { memo, useCallback, useMemo } from "react";
import { FlatList, View, useWindowDimensions } from "react-native";
import { JText, SView, SiteCard, Spacer } from "../../../components";
import useTheme from "../../../theme/ThemeProvider";
import useSites from "hooks/useSites";
import useConfiguration from "../../../configuration/ConfigurationProvider";
import fnStyles from "../SitesHomeScreenStyle";
import { getFirstInSiteType } from "utils/getFirstInSiteType";
import getStatusBarHeight from "utils/getStatusBarHeight";

type Props = {
  navigation: any;
};

const RenderItem = memo(({ item, navigation, items }: any) => {
  const { theme } = useTheme();
  const { configuration } = useConfiguration();

  const displayCat =
    configuration.sites.display.typesSeparator &&
    getFirstInSiteType(items, item);

  return (
    <BoxedLayout key={`site-${item?.id}`}>
      {displayCat && (
        <View style={{ padding: theme.sizings.medium }}>
          <JText
            labelKey={`siteTypes.${item?.siteType?.name}`}
            isBold
            titleLevel={2}
          />
        </View>
      )}
      <SiteCard
        key={item?.id}
        item={item}
        navigation={navigation}
        titleLevel={3}
        fromParent
      />
    </BoxedLayout>
  );
});

export const SectionListZones: React.FC<Props> = ({ navigation }) => {
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();
  const styles = fnStyles(theme, isHorizontalMode({ width, height }));
  const { site, sortAndFilterSites, sites } = useSites();

  const orderedItems = useMemo(
    () =>
      sortAndFilterSites(
        site?.children?.map((c) => sites?.find((s) => s?.id === c?.id)!) || []
      ),
    [site, sortAndFilterSites, sites]
  );

  const spacer = useCallback(() => {
    return <Spacer height={"smallMedium"} backgroundColor={"transparent"} />;
  }, []);

  return (
    <SView>
      <BoxedLayout
        style={{
          marginTop: isHorizontalMode({ width, height })
            ? theme.normalize(100)
            : getStatusBarHeight(),
          paddingVertical: isHorizontalMode({ width, height })
            ? theme.sizings.medium
            : 0,
        }}
      >
        {orderedItems?.length && (
          <FlatList
            data={orderedItems}
            renderItem={({ item }) => (
              <>
                <RenderItem
                  item={item}
                  navigation={navigation}
                  items={orderedItems}
                />
              </>
            )}
            contentContainerStyle={styles.flatList}
            ItemSeparatorComponent={spacer}
          />
        )}
      </BoxedLayout>
    </SView>
  );
};
