import React, { useMemo } from "react";
import { View } from "react-native";
import { StackScreenProps } from "@react-navigation/stack";
import { SitesParamsList } from "../../navigation";
import { NoPosts, PostsList, SView } from "../../components";
import { Post } from "../../types/Post";
import useTheme from "../../theme/ThemeProvider";
import { BoxedLayout } from "components/common/layout/BoxedLayout";
import { useSites } from "../../hooks/useSites";
import { usePosts } from "hooks";
import { t } from "i18next";

type Props = StackScreenProps<SitesParamsList, "sitePosts">;

function compareArticles(articleA: Post, articleB: Post) {
  const dateA = articleA.publishedDate || articleA.startEventDate;
  const dateB = articleB.publishedDate || articleB.startEventDate;

  if (dateA === undefined && dateB === undefined) {
    return 0;
  }

  if (dateA === undefined) {
    return 1;
  }

  if (dateB === undefined) {
    return -1;
  }

  // @ts-ignore
  return new Date(dateB) - new Date(dateA);
}

export const SitePostsScreen: React.FC<Props> = ({ navigation }) => {
  const { theme } = useTheme();
  const { site } = useSites();
  const { setPost } = usePosts();

  const actualPosts = useMemo(() => {
    return site?.posts?.concat(site?.parent?.posts || []).sort(compareArticles);
  }, [site]);

  const onCardPressed = (post: Post, firstFaq?: boolean) => {
    setPost(post);
    navigation.navigate("postDetails", {
      postId: post.id,
      fromSite: true,
      firstFaq: firstFaq ? true : false,
    });
  };

  if (!site) {
    return <View />;
  }

  return (
    <SView>
      {actualPosts && actualPosts.length ? (
        <PostsList
          data={actualPosts ?? []}
          onPress={onCardPressed}
          contentContainerStyle={{
            marginTop: theme.normalize(100),
            paddingBottom: theme.normalize(280),
          }}
          hasRefresh={false}
        />
      ) : (
        <BoxedLayout style={{ paddingTop: theme.normalize(30) }}>
          <NoPosts navigation={navigation} label={t("site.noPost")} />
        </BoxedLayout>
      )}
    </SView>
  );
};
