import useTheme from "../../../theme/ThemeProvider";
import React from "react";
//import { useTranslation } from "react-i18next";
import { font, JText } from "./JText";
import { ThemeConfigFontSizes } from "theme/ThemeTypes";
import { Platform, useWindowDimensions } from "react-native";

type Props = {
  label: string;
  id: string;
  sizeName?: keyof ThemeConfigFontSizes;
};

export const JLabel: React.FC<Props> = ({
  label,
  id,
  sizeName = "mediumLarge",
}: Props) => {
  //const { t } = useTranslation();

  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();

  const templateFont = font(
    theme,
    {
      isTitleText: false,
      sizeName,
      isButtonText: false,
      colorName: "darkest",
    },
    {
      forceSize: false,
      titleLevel: undefined,
      titleLevelStyle: undefined,
      width,
      height,
    }
  );

  const styleNative = {
    paddingBottom: theme.normalize(theme.sizings.medium),
  };

  return Platform.OS === "web" ? (
    <label
      htmlFor={id}
      style={{
        ...templateFont,
        paddingBottom: theme.normalize(theme.sizings.small),
        fontWeight: 700,
      }}
    >
      {label}
    </label>
  ) : (
    <JText
      label={label}
      sizeName={sizeName}
      fontWeight={"500"}
      style={styleNative}
    />
  );
};
