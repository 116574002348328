import { StackScreenProps } from "@react-navigation/stack";
import { BoxedLayout } from "components/common/layout/BoxedLayout";
import React, { useEffect, useState } from "react";
import {
  BackgroundLayout,
  Button,
  Card,
  JText,
  JTextInput,
  SView,
  Spacer,
} from "../../components";
import useUser from "../../hooks/useUser";
import { ParamsParamsList } from "../../navigation";
import useTheme from "../../theme/ThemeProvider";
import fnStyles from "./ChangePasswordScreenStyle";
import useConfiguration from "../../configuration/ConfigurationProvider";
import { UserTypesPicker } from "components/common/userTypesPicker";
import { useTranslation } from "react-i18next";

type Props = StackScreenProps<ParamsParamsList, "changeInfos">;

/* RegExp qui vérifie les numéros de téléphone mobiles 06 et 07
 * Cas valides :
 *  0644444444, 06 44 44 44 44, 06-44-44-44-44, 06.44.44.44.44, +33644444444, 0033644444444, +33(0)644444444
 * Cas d'échec :
 *   06444444444444 (trop de chiffres), 06 (pas assez de chiffres), 06n4444444 (caractères alphabétiques)
 */
const mobilePhoneRegExp = new RegExp(
  "^(?:(?:\\+33|0033|0)(?:\\s?\\(0\\)\\s?|[-.\\s]?)?)?[67][-\\.\\s]?\\d{2}[-\\.\\s]?\\d{2}[-\\.\\s]?\\d{2}[-\\.\\s]?\\d{2}$"
);

// RegExp pour vérifier les séparateurs
const consecutiveSeparatorsRegex = new RegExp(/[.\s/-]{2,}/);

export const ChangeInfosScreen: React.FC<Props> = ({ navigation }) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const styles = fnStyles(theme);

  const {
    user,
    updateUser,
    enableSmsNotifications,
    errorMessage,
    cleanError,
    refreshUser,
  } = useUser();
  const { configuration } = useConfiguration();

  const [name, setName] = useState(user?.name || "");
  const [phoneNumber, setPhoneNumber] = useState(
    user?.phoneNumber || undefined
  );
  const [phoneNumberFormatErrorMessage, setPhoneNumberFormatErrorMessage] =
    useState("");
  const [canChangeInfos, setCanChangeInfos] = useState(false);
  const [userType, setUserType] = useState<string | undefined>(undefined);

  // F5
  useEffect(() => {
    if (user) {
      setName(user.name);
      setPhoneNumber(user?.phoneNumber ?? "");
      setUserType(user?.userType?.id ?? undefined);
    }
  }, [user]);

  useEffect(() => {
    if (phoneNumber) {
      const verifyConsecutiveSeparators =
        consecutiveSeparatorsRegex.test(phoneNumber);
      if (verifyConsecutiveSeparators) {
        setPhoneNumberFormatErrorMessage(t("auth.badMobilePhoneFormat"));
        setCanChangeInfos(!verifyConsecutiveSeparators);
      } else {
        const phoneNumberFormatIsValid = mobilePhoneRegExp.test(
          phoneNumber.replace(/[.\s/-]/g, "")
        );
        setPhoneNumberFormatErrorMessage(
          phoneNumberFormatIsValid ? "" : t("auth.badMobilePhoneFormat")
        );
        setCanChangeInfos(phoneNumberFormatIsValid);
      }
    } else {
      setPhoneNumberFormatErrorMessage("");
      setCanChangeInfos(true);
    }
  }, [t, phoneNumber]);

  useEffect(() => {
    cleanError();
  }, [cleanError]);

  const showMobileField =
    enableSmsNotifications || configuration.profile.phone.share;

  const onSubmit = async () => {
    const formatedData: any = {};

    formatedData.name = name;

    if (phoneNumber) {
      const cleanPhoneNumber = phoneNumber?.replace(/[.\s/-]/g, "");
      setPhoneNumberFormatErrorMessage("");
      const phoneNumberValidator = mobilePhoneRegExp.test(cleanPhoneNumber);
      if (!phoneNumberValidator) {
        setPhoneNumberFormatErrorMessage(t("auth.badMobilePhoneFormat"));
        return;
      }
      formatedData.phoneNumber = cleanPhoneNumber;
    } else {
      formatedData.phoneNumber = null;
    }

    if (userType) {
      formatedData.userType = userType;
    }

    const res = await updateUser(formatedData);
    refreshUser();

    // TODO Alert if not working

    if (res) {
      navigation.navigate("paramsHome");
    }
  };

  useEffect(() => {
    if (name?.length > 0) {
      setCanChangeInfos(true);
    }
  }, [name]);

  return (
    <BackgroundLayout>
      <SView>
        <BoxedLayout style={styles.contentContainer}>
          <Card>
            <JText
              labelKey={"auth.editMyAccount"}
              isBold
              centered
              titleLevel={2}
              titleLevelStyle={2}
            />
            <JTextInput
              name="username"
              value={name || ""}
              onChangeValue={setName}
              label={t("common.username")}
              icon="user"
              containerStyle={{
                marginTop: theme.normalize(theme.sizings.mediumLarge),
              }}
            />
            <Spacer height="smallMedium" backgroundColor={"transparent"} />
            <JText labelKey="auth.usernameLegende" sizeName="average" />

            {showMobileField && (
              <>
                <JTextInput
                  name="phone"
                  value={phoneNumber || ""}
                  onChangeValue={setPhoneNumber}
                  label={t("auth.phone")}
                  icon="mobile"
                  containerStyle={{
                    marginTop: theme.normalize(theme.sizings.mediumLarge),
                  }}
                  colorName={phoneNumberFormatErrorMessage ? "danger" : "dark"}
                  error={!!phoneNumberFormatErrorMessage}
                />
                {phoneNumberFormatErrorMessage ? (
                  <>
                    <Spacer height="medium" backgroundColor={"transparent"} />
                    <JText
                      label={phoneNumberFormatErrorMessage}
                      colorName={"danger"}
                      sizeName="mediumLarge"
                    />
                  </>
                ) : (
                  <></>
                )}

                {errorMessage ? (
                  <>
                    <Spacer height="medium" backgroundColor={"transparent"} />
                    <JText
                      label={errorMessage}
                      colorName={"danger"}
                      sizeName="mediumLarge"
                      centered
                    />
                  </>
                ) : (
                  <></>
                )}
                <Spacer height="smallMedium" backgroundColor={"transparent"} />
                {(configuration.profile.phone.share ||
                  configuration.profile.phone.sms) && (
                  <JText
                    labelKey="auth.phoneLegende"
                    sizeName="average"
                    isBold
                  />
                )}
                {configuration.profile.phone.sms && (
                  <JText labelKey="auth.phoneLegendeSms" sizeName="average" />
                )}
                {configuration.profile.phone.share && (
                  <JText labelKey="auth.phoneLegendeShare" sizeName="average" />
                )}
              </>
            )}

            {configuration && configuration?.profile?.features?.hasUserType && (
              <UserTypesPicker userType={userType} setUserType={setUserType} />
            )}

            <Button
              type={"gradient"}
              labelKey="common.validate"
              onPress={onSubmit}
              containerStyle={styles.button}
              disabled={!canChangeInfos}
            />
          </Card>
        </BoxedLayout>
      </SView>
    </BackgroundLayout>
  );
};
