import React from "react";
import fnStyles from "./HeaderLogoStyle";
import useTheme from "../../../theme/ThemeProvider";
import { isHorizontalMode } from "components/utils/deviceMode";
import { Image, useWindowDimensions } from "react-native";
import useConfiguration from "../../../configuration/ConfigurationProvider";

export const HeaderLogo: React.FC = () => {
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();
  const { configuration } = useConfiguration();

  const styles = fnStyles(
    theme,
    isHorizontalMode({ width, height }),
    configuration?.logo?.width
  );

  const marginLeft = {
    marginLeft: isHorizontalMode({ width, height })
      ? theme.normalize(theme.layout.headerHeight)
      : theme.normalize(theme.sizings.small),
  };

  return (
    <Image
      source={theme.images.logo}
      resizeMode="contain"
      style={[styles.logo, marginLeft]}
      aria-label={theme.texts.appName}
    />
  );
};
