import React from "react";
import {
  Platform,
  StyleProp,
  View,
  ViewStyle,
  useWindowDimensions,
} from "react-native";
import fnStyles from "./JHtmlStyle";
import HTML from "react-native-render-html";
import * as Linking from "expo-linking";
import useTheme from "../../../theme/ThemeProvider";
import truncate from "./../../../utils/getTruncatedText";

type Props = {
  source: string;
  truncateLength?: number;
  truncateKeepBr?: boolean;
  isHighlighted?: boolean;
  style?: StyleProp<ViewStyle>;
};

export const JHtml = React.memo((props: Props) => {
  const {
    source,
    truncateLength = -1,
    truncateKeepBr = false,
    isHighlighted = false,
    style,
  } = props;
  const { width } = useWindowDimensions();

  const { theme } = useTheme();
  const styles = fnStyles(theme, isHighlighted);

  // Remove header tags on dynamic texts
  // <h1> à <h6> et leur contenu
  const regex = /<h[1-6][^>]*>(.*?)<\/h[1-6]>/gis;

  // Remplacer les balises <h1> à <h6> par <p><strong>...</strong></p>
  const transformedHtmlSource = source.replace(
    regex,
    "<p><strong>$1</strong></p>"
  );

  return (
    <View style={style}>
      <HTML
        contentWidth={width}
        source={{
          html:
            truncateLength > 0
              ? truncate(transformedHtmlSource, truncateLength, truncateKeepBr)
              : transformedHtmlSource,
        }}
        /* Autre solution intéressante pour la mise en forme, mais ne retirait pas le tag testId
        customHTMLElementModels={{
          h2: defaultHTMLElementModels.div.extend({
            contentModel: HTMLContentModel.block,
            mixedUAStyles: {
              fontWeight: "700",
            },
          }),
        }}*/
        renderersProps={{
          a: {
            onPress: (_evt: any, href: string) => {
              if (Platform.OS === "web") {
                window.open(href, "_blank");
              } else {
                Linking.openURL(href);
              }
            },
          },
        }}
        tagsStyles={{
          body: styles.p,
          div: styles.p,
          p: styles.p,
          span: styles.p,
          strong: styles.p,
          i: styles.p,
          em: styles.p,
          b: styles.p,
          a: { ...styles.p, ...styles.link },
          li: {
            position: "relative",
            top: -theme.sizings.tiny,
            paddingLeft: theme.sizings.tiny,
          },
        }}
      />
    </View>
  );
});
