import {
  CardStyleInterpolators,
  createStackNavigator,
} from "@react-navigation/stack";
import { isHorizontalMode } from "components/utils/deviceMode";
import { usePosts } from "hooks";
import useSites from "hooks/useSites";
import React, { useCallback, useMemo } from "react";
import { useWindowDimensions } from "react-native";
import { ReactHomeScreen } from "screens/request";
import { Clickable, Icon } from "../components";
import {
  PostDetailsScreen,
  SiteDetailsScreen,
  SitesHomeScreen,
} from "../screens";
import useTheme from "../theme/ThemeProvider";
import getWording from "../utils/wording";
import fnStyles from "./HeaderStyle";
import { SitesParamsList } from "./SitesParamsList";
import { useTranslation } from "react-i18next";
import HeaderTitleWithSubtitle from "./HeaderTitleWithSubtitle";
import useConfiguration from "../configuration/ConfigurationProvider";
import { SiteSuggestionScreen } from "screens/sites/SiteSuggestionScreen";

const SitesStack = createStackNavigator<SitesParamsList>();

export const SitesStackNavigator: React.FC = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { width, height } = useWindowDimensions();
  const styles = fnStyles(theme, isHorizontalMode({ width, height }));
  const { post } = usePosts();
  const { site, setShowFilters, showFilters } = useSites();
  const { configuration } = useConfiguration();

  const headerLeftStyle = useMemo(
    () => ({
      marginLeft: isHorizontalMode({ width, height })
        ? 85
        : theme.normalize(theme.sizings.small),
    }),
    [height, theme, width]
  );

  const headerLeft = useCallback(
    (props: any) => (
      <Clickable
        onPress={props.onPress!}
        style={headerLeftStyle}
        ariaLabel="Retour à la page précédente"
      >
        <Icon
          name={"chevron-small-left"}
          type={"Entypo"}
          colorName={"white"}
          sizeName={"high"}
        />
      </Clickable>
    ),
    [headerLeftStyle]
  );

  const renderHeaderTitleWithSubtitle = useCallback(
    (primaryText?: string, secondaryText?: string) => {
      return (
        <HeaderTitleWithSubtitle
          primaryText={primaryText || ""}
          secondaryText={secondaryText}
        />
      );
    },
    []
  );

  const listHeaderRight = () => {
    return (
      <Clickable
        onPress={() => {
          setShowFilters(!showFilters);
        }}
        hitBox={10}
        style={{ marginRight: theme.normalize(12) }}
      >
        <Icon
          type="Fontisto"
          name="search"
          colorName="white"
          sizeName="large"
        />
      </Clickable>
    );
  };

  const centeredTitles = ["chatSelectType", "siteSuggestion"];

  return (
    <SitesStack.Navigator
      screenOptions={({ route }) => ({
        cardStyleInterpolator: CardStyleInterpolators.forNoAnimation,
        headerTitleAlign: centeredTitles.includes(route.name)
          ? "center"
          : "left",
        headerShown: route.name !== "chatSelectType",
      })}
    >
      <SitesStack.Screen
        name={"siteHome"}
        component={SitesHomeScreen}
        options={{
          title: `${t("header.allSites", {
            wording: getWording(configuration).pluralCapitalize,
          })} - ${theme.texts.appName}`,
          headerTitle:
            t("header.allSites", {
              wording: getWording(configuration).pluralCapitalize,
            }) ?? "",
          headerStyle: styles.mainContainerWithoutShadow,
          headerTintColor: theme.colors.white,
          headerTitleStyle: styles.titleWithoutLeft,
          headerRight: listHeaderRight,
        }}
      />
      <SitesStack.Screen
        name={"siteDetails"}
        component={SiteDetailsScreen}
        options={() => ({
          title: site?.name
            ? `${site.name} - ${theme.texts.appName}`
            : `${t("header.siteDetails")} - ${theme.texts.appName}`,
          headerTitle: () =>
            renderHeaderTitleWithSubtitle(site?.name, site?.location),
          headerLeft: headerLeft,
          headerStyle: site?.mainImage
            ? styles.containerStraight
            : styles.container,
        })}
      />
      <SitesStack.Screen
        name={"postDetails"}
        component={PostDetailsScreen}
        options={() => ({
          title: `${t("header.postDetails")} - ${theme.texts.appName}`,
          headerTitle: () =>
            renderHeaderTitleWithSubtitle(post?.title, post?.site?.name),
          headerLeft: headerLeft,
          headerStyle: post?.mainImage
            ? styles.containerStraight
            : styles.container,
        })}
      />
      <SitesStack.Screen
        name={"chatSelectType"}
        component={ReactHomeScreen}
        options={{
          title: `${t("header.react")} - ${theme.texts.appName}`,
          headerTitle: t("header.react"),
          headerStyle: styles.container,
          headerTintColor: theme.colors.white,
          headerTitleStyle: styles.titleWithoutLeft,
        }}
      />
      <SitesStack.Screen
        name={"chatSelectUserType"}
        component={ReactHomeScreen}
        options={{
          title: `${t("header.react")} - ${theme.texts.appName}`,
          headerTitle: t("header.react"),
          headerStyle: styles.container,
          headerTintColor: theme.colors.white,
          headerTitleStyle: styles.titleWithoutLeft,
        }}
      />
      <SitesStack.Screen
        name={"siteSuggestion"}
        component={SiteSuggestionScreen}
        options={{
          title: `${t("header.suggestion", {
            wording: getWording(configuration).singular,
          })} - ${theme.texts.appName}`,
          headerTitle: t("header.suggestion", {
            wording: getWording(configuration).singular,
          }),
          headerLeft: headerLeft,
          headerStyle: styles.container,
          headerTintColor: theme.colors.white,
          headerTitleStyle: styles.titleWithoutLeft,
        }}
      />
    </SitesStack.Navigator>
  );
};
