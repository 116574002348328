import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { StackScreenProps } from "@react-navigation/stack";
import { isHorizontalMode } from "components/utils/deviceMode";
import React, { useCallback, useEffect } from "react";
import { View, useWindowDimensions } from "react-native";
import useTheme from "theme/ThemeProvider";
import { BackgroundLayout, TopNavigationBar } from "../../components";
import useOrganization from "../../hooks/useOrganization";
import { SitesParamsList } from "../../navigation";
import { MapScreen } from "./MapScreen";
import fnStyles from "./SitesHomeScreenStyle";
import { SitesListScreen } from "./SitesListScreen";
import { SectionList } from "./sections/sectionList";
import { SectionMap } from "./sections/sectionMap";
import useSites from "hooks/useSites";
import { useTranslation } from "react-i18next";
import { SiteSearchField } from "components/sites/SiteSearchField";

type Props = StackScreenProps<SitesParamsList, "siteHome">;

const Tab = createMaterialTopTabNavigator<SitesParamsList>();

export const SitesHomeScreen: React.FC<Props> = ({ navigation }) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { width, height } = useWindowDimensions();
  const styles = fnStyles(theme, isHorizontalMode({ width, height }));
  const { getSites, setMapPolygonFilter, setFilters } = useSites();

  useEffect(() => {
    getSites();
    setMapPolygonFilter([]);
    setFilters(undefined);
  }, [getSites, setMapPolygonFilter, setFilters]);

  const { organization: currentOrganization } = useOrganization();

  const hideMap = currentOrganization && !currentOrganization?.activeModuleMap;

  const tabBar = useCallback(
    (props: any) => <TopNavigationBar {...props} averageLow />,
    []
  );

  return hideMap ? (
    <BackgroundLayout>
      <SectionList navigation={navigation} />
    </BackgroundLayout>
  ) : (
    <BackgroundLayout
      style={{
        marginTop: isHorizontalMode({ width, height })
          ? 0
          : theme.normalize(-30),
      }}
    >
      {isHorizontalMode({ width, height }) ? (
        <View style={styles.containerWeb}>
          <View style={styles.left}>
            <SectionMap />
          </View>
          <View style={styles.right}>
            <SectionList
              navigation={navigation}
              //user={user!!}
            />
          </View>
        </View>
      ) : (
        <View style={{ flex: 1, paddingTop: theme.normalize(30) }}>
          {
            <Tab.Navigator
              initialRouteName="siteList"
              screenOptions={() => ({
                tabBarScrollEnabled: false,
                swipeEnabled: false,
                title: `${t("header.search")} - ${theme.texts.appName}`,
                headerTitle: t("header.search") ?? "",
              })}
              tabBar={tabBar}
            >
              <Tab.Screen name="siteList" component={SitesListScreen} />
              <Tab.Screen name="siteMap" component={MapScreen} />
            </Tab.Navigator>
          }
          <SiteSearchField />
        </View>
      )}
    </BackgroundLayout>
  );
};
