import {
  CardStyleInterpolators,
  StackScreenProps,
  createStackNavigator,
} from "@react-navigation/stack";
import { isHorizontalMode } from "components/utils/deviceMode";
import { usePosts } from "hooks";
import useSites from "hooks/useSites";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useWindowDimensions } from "react-native";
import { Clickable, Icon } from "../../components";
import { SitesParamsList } from "../../navigation";
import fnStyles from "../../navigation/HeaderStyle";
import useTheme from "../../theme/ThemeProvider";
import { ThreadType } from "../../types/ThreadType";
import { ReactMessageScreen } from "./ReactMessageScreen";
import { ReactParamsList } from "./ReactParamsList";
import { useTranslation } from "react-i18next";
import { ChatSelectType } from "../chat/ChatSelectType";
import { ChatContactProfileScreen } from "screens";
import { ChatSelectUserType } from "screens/chat";

const ReactStack = createStackNavigator<ReactParamsList>();

type Props = StackScreenProps<SitesParamsList, "chatSelectType">;

export const ReactHomeScreen: React.FC<Props> = ({ route }) => {
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();
  const { t } = useTranslation();
  const styles = fnStyles(theme, isHorizontalMode({ width, height }));

  const headerLeftStyle = useMemo(
    () => ({
      marginLeft: isHorizontalMode({ width, height })
        ? 85
        : theme.normalize(theme.sizings.small),
    }),
    [height, theme, width]
  );

  const { site, fetchSite } = useSites();
  const { post, fetchPost } = usePosts();
  // const [threadType] = useState<ThreadType>();
  const [initialThreadType] = useState<ThreadType>();

  useEffect(() => {
    if (route.params.postId && (!post || post.id !== route.params.postId)) {
      fetchPost(route.params.postId);
    }
  }, [route.params.postId, post, fetchPost]);

  useEffect(() => {
    if (route.params.siteId && (!site || site.id !== route.params.siteId)) {
      fetchSite(route.params.siteId);
    }
  }, [route.params.siteId, site, fetchSite]);

  const headerLeft = useCallback(
    (props: any) => (
      <Clickable
        onPress={props.onPress!}
        style={headerLeftStyle}
        ariaLabel="Retour à la page précédente"
      >
        <Icon
          name={"chevron-small-left"}
          type={"Entypo"}
          colorName={"white"}
          sizeName={"high"}
        />
      </Clickable>
    ),
    [headerLeftStyle]
  );

  return (
    <ReactStack.Navigator
      screenOptions={{
        cardStyleInterpolator: CardStyleInterpolators.forNoAnimation,
        headerTitleAlign: "center",
      }}
      initialRouteName={"reactType"}
    >
      <ReactStack.Screen
        name={"reactType"}
        component={ChatSelectType}
        options={{
          title: `${t("header.react")} - ${theme.texts.appName}`,
          headerTitle: t("header.react") ?? "",
          headerStyle: styles.container,
          headerTintColor: theme.colors.white,
          headerTitleStyle: styles.title,
          headerLeft: headerLeft,
        }}
        initialParams={{ siteId: Number(site?.id), postId: post?.id }}
      />
      <ReactStack.Screen
        name={"chatSelectUserType"}
        component={ChatSelectUserType}
        options={{
          title: `${t("header.react")} - ${theme.texts.appName}`,
          headerTitle: t("header.react") ?? "",
          headerStyle: styles.container,
          headerTintColor: theme.colors.white,
          headerTitleStyle: styles.title,
          headerLeft: headerLeft,
        }}
        initialParams={{ siteId: Number(site?.id), postId: post?.id }}
      />
      <ReactStack.Screen
        name={"reactMessage"}
        component={ReactMessageScreen}
        options={() => ({
          title: `${t("header.yourMessage")} - ${theme.texts.appName}`,
          titleTitle: t("header.yourMessage") ?? "",
          headerStyle: styles.container,
          headerTintColor: theme.colors.white,
          headerTitleStyle: styles.title,
          headerLeft: headerLeft,
        })}
        initialParams={{
          siteId: Number(site?.id),
          postId: post?.id,
          threadId: initialThreadType?.id,
        }}
      />
      <ReactStack.Screen
        name={"contactProfile"}
        component={ChatContactProfileScreen}
        options={{
          title: `${t("header.contactInfo")} - ${theme.texts.appName}`,
          headerTitle: t("header.contactInfo") ?? "",
          headerStyle: styles.container,
          headerTintColor: theme.colors.white,
          headerTitleStyle: styles.title,
          headerLeft: headerLeft,
        }}
      />
    </ReactStack.Navigator>
  );
};
