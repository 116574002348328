import { Platform, StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme) =>
  StyleSheet.create({
    select: {
      backgroundColor: theme.colors.fieldFill,
      color: theme.colors.greyDark,
      paddingLeft: theme.normalize(16),
      borderRadius: theme.normalize(theme.radius.small),
      borderColor: theme.colors.fieldBorder,
      borderWidth: 1,

      height: theme.normalize(50),
      minHeight: theme.normalize(40),
      maxHeight: theme.normalize(400),

      fontSize: theme.fontSizes.large,

      marginTop: theme.normalize(theme.sizings.medium),
    },
  });
