import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme, isSelected: boolean) =>
  StyleSheet.create({
    container: {
      marginBottom: 1,
      paddingHorizontal: theme.sizings.medium,
    },
    card: {
      flex: 1,
      padding: 0,
      borderRadius: theme.normalize(15),
      // overflow: "hidden",
      borderWidth: isSelected ? theme.normalize(3) : undefined,
      borderColor: isSelected ? theme.colors.headerBackground : undefined,
      shadowColor: theme.colors.dark,
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 0.2,
      shadowRadius: 1.41,
      elevation: 2,
    },
    content: {
      padding: theme.normalize(10),
    },
    dividerBox: {
      paddingHorizontal: theme.sizings.small,
    },
  });
