import { ThemeConfig } from "./ThemeTypes";

const defaultProperties: ThemeConfig = {
  fonts: {
    fontTitle: "Roboto_400Regular",
    fontBody: "Roboto_400Regular",
  },
  colors: {
    transparent: "transparent",
    dark: "#212121",
    primary: "#FF9253",
    primaryDark: "#F28749",
    primaryLight: "#FAF0E6",
    primaryDarker: "#95542E",
    grey: "#776E6E", //"#727272", //"#9E9E9E"
    greyLight: "#E4E4E4",
    greyDark: "#595959", // "#646464",
    white: "#FAFAFA",
    slate: "#f3f4f6",
    fieldFill: "#e9e8f3",
    fieldBorder: "#d8d8e0",
    whiteLight: "#eaeaea",
    secondary: "#FF316F",
    secondaryLight: "#FFA6B4",
    info: "#34A6FF",
    infoMedium: "#1F67A0", //"#34A6FF",
    infoLight: "#E6F6FF",
    infoDark: "#0784e6",
    event: "#ac40bc",
    eventLight: "#e9b3f1",
    eventLighter: "#fbe0ff",
    success: "#4CAF50",
    successLight: "#A5D6A7",
    warning: "#FFC107",
    warningLight: "#FFE082",
    danger: "#F44336",
    dangerLight: "#edc1c1",
    blurBackground: "#F2F2F7",
    tabActiveText: "#ffffff",
    tabActiveBackground: "#FF316F",
    gradientStart: "#FF9253",
    gradientEnd: "#FF316F",
    gradientText: "#ffffff",
    headerText: "#ffffff",
    headerBackground: "#FF9253",
    emphasisText: "#FF9253",
    rootMenuText: "#FF9253",
    tinyElementsText: "#ffffff",
    tinyElementsBackground: "#FF9253",
    transparentDark: "rgba(0,0,0,0.4)",
  },
  images: {
    logo: require("assets/logo.png"),
    background: require("assets/background.jpg"),
    introStep1: require("assets/introStep11.jpg"),
    introStep2: require("assets/introStep22.jpg"),
    introStep3: require("assets/introStep33.jpg"),
    pictoBubble: require("assets/picto-bubbles.png"),
    pictoClipboard: require("assets/picto-clipboard.png"),
    pictoSearch: require("assets/picto-search.png"),
  },
  sizings: {
    tiny: 3,
    small: 4,
    smallMedium: 8,
    default: 12,
    medium: 16,
    mediumLarge: 24,
    large: 32,
    larger: 40,
    high: 48,
    huge: 56,
  },
  layout: {
    headerHeight: 64,
    navLeftWidth: 80,
    navBottomHeight: 64,
    containerMaxWidth: 700,
    minimalAccessible: 44,
  },
  radius: {
    small: 5,
    medium: 10,
    mediumLarge: 20,
    large: 36,
    rounded: 100,
  },
  fontSizes: {
    tiny: 2,
    small: 4,
    smallMedium: 8,
    medium: 12,
    average: 14,
    mediumLarge: 16,
    largeLow: 18,
    large: 20,
    largeHigh: 25,
    larger: 30,
    high: 40,
  },
  texts: {
    appName: "InformezMoi",
    icons: {
      introStep1Icon: "users",
      introStep2Icon: "megaphone",
      introStep3Icon: "comments-o",
      introStep1IconType: "FontAwesome",
      introStep2IconType: "Entypo",
      introStep3IconType: "FontAwesome",
    },
    fr: {
      introStep1Title:
        "InformezMoi est un service proposé par les entreprises, collectivités qui souhaitent dialoguer avec vous dans le cadre de leurs activités, qu'il s'agisse d'un projet d'aménagement, d'un chantier, d'un site d'exploitation.",
      introStep2Title:
        "Choisissez la zone d'informations souhaitée et recevez toutes les informations qui vous concernent sur les chantiers, les projets autour de vous.",
      introStep3Title:
        "Dialoguez en direct avec les responsables des sites, faites part de vos attentes, de vos questions, de vos observations.",
      paramAlertTitle: "Perturbations",
      paramEventTitle: "Événements",
      paramInfoTitle: "Informations",
      paramAlertLegend: "Nuisance et risque en temps réel",
      paramEventLegend: "Avancées des travaux en temps réel",
      paramInfoLegend: "Publications d'actualités",
    },
  },
};

export default defaultProperties;
