import { useFocusEffect } from "@react-navigation/native";
import { StackScreenProps } from "@react-navigation/stack";
import React, { useCallback, useEffect, useState } from "react";
import { ImageBackground, View } from "react-native";
import {
  BackgroundLayout,
  JText,
  NoSites,
  SView,
  SimpleList,
  Spacer,
  SimpleListItem,
  SimpleListItemContent,
  ForegroundButton,
} from "../../components";
import useTheme from "../../theme/ThemeProvider";
import fnStyles from "./../request/ReactScreenStyle";
import { useUser } from "hooks";
import { UserType } from "types/User";
import { ReactParamsList } from "screens/request/ReactParamsList";
import { useTranslation } from "react-i18next";

type Props = StackScreenProps<ReactParamsList, "chatSelectUserType">;

export const ChatSelectUserType: React.FC<Props> = ({ navigation, route }) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);
  const { t } = useTranslation();

  const { user, userTypes, fetchUserTypes } = useUser();
  const [userType, setUserType] = useState(user?.userType?.id ?? undefined);

  useFocusEffect(
    useCallback(() => {
      fetchUserTypes();
    }, [fetchUserTypes])
  );

  useEffect(() => {
    fetchUserTypes();
  }, [fetchUserTypes]);

  const onCardPressed = (type: UserType) => {
    setUserType(type.id);
  };

  const onContinuePressed = () => {
    navigation.navigate("reactMessage", {
      userType: userType,
      siteId: route?.params?.siteId,
      postId: route?.params?.postId,
      lat: route.params?.lat,
      lng: route.params?.lng,
      address: route.params?.address,
      threadTypeId: route?.params?.threadTypeId,
      threadType: route?.params?.threadType,
    });
  };

  const spacer = useCallback(
    () => <Spacer height={"medium"} backgroundColor={"blurBackground"} />,
    []
  );
  return (
    <>
      <BackgroundLayout
        style={{
          marginTop: theme.normalize(-30),
          paddingTop: theme.normalize(30),
        }}
      >
        <ForegroundButton
          handleButtonPressed={onContinuePressed}
          buttonLabel={
            userType
              ? t("chat.continueButton")
              : t("chat.ignoreAndContinueButton")
          }
          iconName="keyboard-arrow-right"
          iconType="MaterialIcons"
          bottomBar={false}
          spanHorizontal
        />
        <SView>
          <View>
            {userTypes && userTypes.length > 0 ? (
              <>
                <Spacer height={"large"} backgroundColor={"transparent"} />

                {userTypes?.length > 0 ? (
                  <JText
                    labelKey="chat.choseUserType"
                    isBold
                    centered
                    titleLevel={2}
                    style={{ marginTop: theme.sizings.mediumLarge }}
                  />
                ) : (
                  <View style={styles.icon}>
                    <ImageBackground
                      source={theme.images.pictoClipboard}
                      style={styles.image}
                    />
                    <JText
                      labelKey="chat.noUserTypes"
                      centered
                      style={styles.text}
                    />
                  </View>
                )}
                <SimpleList<UserType>
                  data={userTypes}
                  renderItem={({ item }: { item: UserType }) => {
                    return (
                      <SimpleListItem
                        item={item}
                        onPress={onCardPressed}
                        isSelected={userType === item?.id}
                      >
                        <SimpleListItemContent
                          label={item.name}
                          media={item?.icon?.url}
                        />
                      </SimpleListItem>
                    );
                  }}
                  keyExtractor={(item: UserType) => item.id}
                  ItemSeparatorComponent={spacer}
                  contentContainerStyle={[
                    {
                      paddingTop: theme.normalize(20),
                      paddingBottom: theme.normalize(150),
                    },
                    // contentContainerStyle,
                  ]}
                />
              </>
            ) : (
              <NoSites />
            )}
          </View>
        </SView>
      </BackgroundLayout>
    </>
  );
};
