import { StyleProp, View, ViewStyle, Switch, Platform } from "react-native";
import React from "react";
import useTheme from "../../../theme/ThemeProvider";
import { ColorType } from "theme/ThemeTypes";
import { Types } from "types";
import { Icon } from "../icon";

type Props = {
  value: boolean;
  onToggle: (boolean: any) => void;
  onColor?: string;
  offColor?: string;
  disabled?: boolean;
  style?: StyleProp<ViewStyle> | null;
  // icon
  iconType?: Types["iconTypes"];
  iconName?: string;
  iconCustomSize?: number;
  iconColorName?: ColorType;
  iconStyle?: StyleProp<ViewStyle>;
  accessibilityLabel?: string;
  accessibilityLabelledBy?: string;
};

export const JSwitch: React.FC<Props> = ({
  value,
  onToggle,
  onColor,
  offColor,
  disabled = false,
  style,
  // icon
  iconName,
  iconType = "AntDesign",
  iconCustomSize,
  iconColorName = "white",
  accessibilityLabel,
  accessibilityLabelledBy,
}: Props) => {
  const { theme } = useTheme();

  onColor = onColor ?? theme.colors.info;
  offColor = offColor ?? theme.colors.greyLight;
  console.log(offColor);

  const defaultStyle = {
    transform: [
      { scaleX: Platform.OS === "android" ? 1.5 : 1.1 },
      { scaleY: Platform.OS === "android" ? 1.5 : 1.1 },
    ],
    height: theme.normalize(30),
  } as ViewStyle;

  const defaultIconStyle = {
    paddingHorizontal: Platform.OS !== "android" ? theme.sizings.medium / 4 : 0,
    flexDirection: "row",
    width: "100%",
    height: theme.normalize(30),
    justifyContent: value ? "flex-start" : "flex-end",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left:
      Platform.OS === "android"
        ? value
          ? theme.normalize(10)
          : theme.normalize(-7)
        : 0,
  } as ViewStyle;

  return (
    <View>
      <Switch
        value={value}
        onValueChange={onToggle}
        disabled={disabled}
        trackColor={{ true: onColor, false: offColor }}
        ios_backgroundColor={offColor}
        thumbColor={theme.colors.white}
        activeThumbColor={theme.colors.white}
        style={style || defaultStyle}
        // accesssibility
        accessibilityLabel={accessibilityLabel}
        accessibilityLabelledBy={accessibilityLabelledBy}
      />
      {iconName && (
        <View style={defaultIconStyle} pointerEvents="none">
          <Icon
            name={iconName}
            type={iconType}
            colorName={iconColorName ?? "black"}
            sizeName={Platform.OS === "android" ? "medium" : "mediumLarge"}
            customSize={iconCustomSize}
          />
        </View>
      )}
    </View>
  );
};
