import React from "react";
import fnStyles from "./HeaderLogoStyle";
import useTheme from "../../../theme/ThemeProvider";
import { Image, useWindowDimensions } from "react-native";
import { isHorizontalMode } from "components/utils/deviceMode";
import useConfiguration from "../../../configuration/ConfigurationProvider";

export const NavbarLogo: React.FC = () => {
  const { theme } = useTheme();
  const { configuration } = useConfiguration();
  const { width, height } = useWindowDimensions();

  const styles = fnStyles(
    theme,
    isHorizontalMode({ width, height }),
    configuration?.logo?.width
  );

  return (
    <Image
      source={theme.images.logo}
      style={styles.logo}
      resizeMode="contain"
      aria-label={theme.texts.appName}
    />
  );
};
