import React, { createContext, useCallback, useState } from "react";
import { Site } from "types/Site";
import { fetchJSON, getStoredData, getStoredObjectData } from "../utils";
import useConfiguration from "./../configuration/ConfigurationProvider";
import useThread from "../hooks/useThread";
import { useLocation } from "hooks";
import { computeDistance } from "utils/computeDistance";
import * as turf from "@turf/turf";

type SiteProviderType = {
  sites?: Site[];
  site?: Site | undefined;
  fetchSite: (siteId: number) => Promise<Site | null>;
  userPosition: any;
  setUserPosition: (userPosition: any) => void;
  setSite: (site: Site | undefined) => void;
  getSites: (followedSites?: number[]) => Promise<[] | null>;
  refreshSites: (followedSites?: number[]) => Promise<[] | null>;
  isFetching: boolean;
  isRefreshing: boolean;
  setIsRefreshing: any;
  errorMessage: string | undefined;
  cleanError: () => void;
  sortAndFilterSites: (
    items?: Site[],
    customFilters?: {
      filterOnShownInApp?: boolean;
      filterOnAllowThreads?: boolean;
      filterOnUseMapPolygon?: boolean;
    },
    customSorts?: {
      sortOnCustomLocation?: any;
    }
  ) => Site[];
  highlightedSiteId: string | number;
  setHighlightedSiteId: (id: string | number) => void;
  showFilters: boolean;
  setShowFilters: (newValue: boolean) => void;
  filters: any;
  setFilters: (newValue: any) => void;
  mapPolygonFilter: { lat: any; lng: any }[];
  setMapPolygonFilter: (newValue: { lat: any; lng: any }[]) => void;
};

export const SitesContext = createContext<SiteProviderType>(
  {} as SiteProviderType
);

type Props = {
  children: React.ReactNode;
};

export const SitesProvider = ({ children }: Props) => {
  const [sites, setSites] = useState<Site[]>();
  const [site, setSite] = useState<Site>();
  const [userPosition, setUserPosition] = useState<any>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [highlightedSiteId, setHighlightedSiteId] = useState<string | number>(
    -1
  );
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState<any>();
  const { locationData } = useLocation();
  const { configuration } = useConfiguration();
  const { getThreadTypes } = useThread();
  const [mapPolygonFilter, setMapPolygonFilter] = useState<
    { lat: any; lng: any }[]
  >([]);

  const fetchSite = useCallback(async (siteId: number) => {
    try {
      setIsFetching(true);
      const res = await fetchJSON({
        url: `sites/public-site/${siteId}`,
        method: "GET",
      });
      const p = res ? (res.id === siteId ? res : null) : null;
      setSite(p);
      return p;
    } catch (err) {
      return null;
    } finally {
      setIsFetching(false);
    }
  }, []);

  const getSites = useCallback(async () => {
    try {
      if (configuration.sites.features.locationMandatory) {
        const locationDataLocal = await getStoredObjectData(
          "local--location-data"
        );

        if (!locationDataLocal) {
          console.error("GROS PROBLEME DE LOCALISATION");
          setSites([]);
          return;
        }
        setIsFetching(true);
        // TODO quand on fera la configuration de cette partie, il faut ajouter un ternaire pour savoir si on doit récupérer la position de l'utilisateur
        const res = await fetchJSON({
          url: `sites/filtered-posts?filters[lat]=${locationDataLocal.lat}&filters[lng]=${locationDataLocal.lng}`,
          method: "GET",
        });

        setSites(res || []);
        return res;
      } else {
        setIsFetching(true);
        const res = await fetchJSON({
          url: "sites/filtered-posts",
          method: "GET",
        });

        setSites(res || []);
        return res;
      }
    } catch {
    } finally {
      setIsFetching(false);
    }
  }, [configuration]);

  const refreshSites = useCallback(async () => {
    try {
      const token = await getStoredData("token");
      if (configuration.sites.features.locationMandatory) {
        const locationDataLocal = await getStoredObjectData(
          "local--location-data"
        );

        if (!locationDataLocal) {
          console.error("GROS PROBLEME DE LOCALISATION");
          setSites([]);
          return;
        }
        setIsRefreshing(true);
        const res = await fetchJSON(
          token
            ? {
                url: `sites/filtered-posts?filters[lat]=${locationDataLocal.lat}&filters[lng]=${locationDataLocal.lng}`,
                method: "GET",
                jwt: token,
              }
            : {
                url: `sites/filtered-posts?filters[lat]=${locationDataLocal.lat}&filters[lng]=${locationDataLocal.lng}`,
                method: "GET",
              }
        );

        setSites(res || []);
        return res;
      } else {
        setIsRefreshing(true);
        const res = await fetchJSON(
          token
            ? {
                url: "sites/filtered-posts",
                method: "GET",
                jwt: token,
              }
            : {
                url: "sites/filtered-posts",
                method: "GET",
              }
        );

        setSites(res || []);
        return res;
      }
    } catch {
      return [];
    } finally {
      setIsRefreshing(false);
    }
  }, [configuration]);

  const sortAndFilterSites = useCallback(
    (
      items?: Site[],
      customFilters?: {
        filterOnShownInApp?: boolean;
        filterOnAllowThreads?: boolean;
        filterOnUseMapPolygon?: boolean;
      }
    ) => {
      let sortedSites = items || [];

      // filters
      if (customFilters?.filterOnShownInApp) {
        sortedSites = sortedSites.filter(
          (item: Site) => item.siteType?.shouldBeDisplayedInApp
        );
      }

      if (customFilters?.filterOnAllowThreads) {
        sortedSites = sortedSites.filter((sItem: Site) => {
          const hasThreadTypes = getThreadTypes(sItem);
          return Boolean(hasThreadTypes?.length);
        });
      }

      if (
        customFilters?.filterOnUseMapPolygon &&
        mapPolygonFilter &&
        mapPolygonFilter.length > 0
      ) {
        const polygon = turf.polygon([
          mapPolygonFilter.map((point) => [point.lng, point.lat]),
        ]);
        sortedSites = sortedSites.filter((sItem: Site) => {
          if (
            sItem.siteType?.isGeo &&
            sItem.geojson?.lat &&
            sItem.geojson?.lng
          ) {
            const pt = turf.point([sItem.geojson.lng, sItem.geojson.lat]);
            return turf.booleanPointInPolygon(pt, polygon);
          }
          return false;
        });
      }

      // sorts
      if (filters) {
        if (filters?.goToUser === true) {
          sortedSites = sortedSites.sort((a: Site, b: Site) => {
            if (a.siteType?.isGeo && !b.siteType?.isGeo) {
              return 1;
            } else if (!a.siteType?.isGeo && b.siteType?.isGeo) {
              return -1;
            } else if (a.siteType?.isGeo && b.siteType?.isGeo) {
              const distanceA = computeDistance(
                a?.geojson?.lat || 0,
                a?.geojson?.lng || 0,
                locationData?.lat ?? 0,
                locationData?.lng ?? 0
              );
              const distanceB = computeDistance(
                b?.geojson?.lat || 0,
                b?.geojson?.lng || 0,
                locationData?.lat ?? 0,
                locationData?.lng ?? 0
              );
              if (distanceA < distanceB) {
                return -1;
              } else if (distanceA > distanceB) {
                return 1;
              }
              return 0;
            } else {
              return 0;
            }
          });
        } else if (filters?.location) {
          sortedSites = sortedSites.sort((a: Site, b: Site) => {
            if (a.siteType?.isGeo && !b.siteType?.isGeo) {
              return 1;
            } else if (!a.siteType?.isGeo && b.siteType?.isGeo) {
              return -1;
            } else if (a.siteType?.isGeo && b.siteType?.isGeo) {
              const distanceA = computeDistance(
                a?.geojson?.lat || 0,
                a?.geojson?.lng || 0,
                filters?.location?.lat ?? 0,
                filters?.location?.lng ?? 0
              );
              const distanceB = computeDistance(
                b?.geojson?.lat || 0,
                b?.geojson?.lng || 0,
                filters?.location?.lat ?? 0,
                filters?.location?.lng ?? 0
              );
              if (distanceA < distanceB) {
                return -1;
              } else if (distanceA > distanceB) {
                return 1;
              }
              return 0;
            } else {
              return 0;
            }
          });
        }
      } else if (configuration.sites.display.typesSeparator) {
        sortedSites = sortedSites.sort(
          (a: Site, b: Site) => (a?.siteType?.id || 0) - (b?.siteType?.id || 0)
        );

        // permet de mettre les catégories géographiques en 1er
        sortedSites = sortedSites.sort((a: Site, b: Site) => {
          if (a.siteType?.isGeo && !b.siteType?.isGeo) {
            return 1;
          } else if (!a.siteType?.isGeo && b.siteType?.isGeo) {
            return -1;
          } else {
            return 0;
          }
        });
      } else if (locationData) {
        sortedSites = sortedSites.sort((a: Site, b: Site) => {
          if (a.siteType?.isGeo && !b.siteType?.isGeo) {
            return 1;
          } else if (!a.siteType?.isGeo && b.siteType?.isGeo) {
            return -1;
          } else if (a.siteType?.isGeo && b.siteType?.isGeo) {
            const distanceA = computeDistance(
              a?.geojson?.lat || 0,
              a?.geojson?.lng || 0,
              locationData?.lat ?? 0,
              locationData?.lng ?? 0
            );
            const distanceB = computeDistance(
              b?.geojson?.lat || 0,
              b?.geojson?.lng || 0,
              locationData?.lat ?? 0,
              locationData?.lng ?? 0
            );
            if (distanceA < distanceB) {
              return -1;
            } else if (distanceA > distanceB) {
              return 1;
            }
            return 0;
          } else {
            return 0;
          }
        });
      }
      return sortedSites;
    },
    [
      configuration.sites.display.typesSeparator,
      getThreadTypes,
      locationData,
      mapPolygonFilter,
      filters,
    ]
  );

  const cleanError = useCallback(() => {
    setErrorMessage(undefined);
  }, []);

  return (
    <SitesContext.Provider
      value={{
        sites,
        getSites,
        site,
        fetchSite,
        setSite,
        isFetching,
        isRefreshing,
        setIsRefreshing,
        refreshSites,
        errorMessage,
        cleanError,
        userPosition,
        setUserPosition,
        sortAndFilterSites,
        highlightedSiteId,
        setHighlightedSiteId,
        showFilters,
        setShowFilters,
        filters,
        setFilters,
        mapPolygonFilter,
        setMapPolygonFilter,
      }}
    >
      {children}
    </SitesContext.Provider>
  );
};
